import './jquery-ui.min.js'
import router from '../../router'
import { blackScholes } from './black-scholes'

let cgs, cgsmail
// Production TESTING
// Production
// UAT TESTING
// UAT
export default {
	cgs: cgs,
	cgsmail: cgsmail,

	/** ************* search combobox ******************/
	jqcombobox: function (selectorelement) {
		$.widget('custom.combobox', {
			_create: function () {
				this.wrapper = $('<span>')
					.addClass('custom-combobox')
					.insertAfter(this.element)

				this.element.hide()
				this._createAutocomplete()
				this._createShowAllButton()
			},

			_createAutocomplete: function () {
				var selected = this.element.children(':selected')
				var value = selected.val() ? selected.text() : ''

				this.input = $('<input>')
					.appendTo(this.wrapper)
					.val(value)
					.attr('title', '')
					.addClass(
						'custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left'
					)
					.autocomplete({
						delay: 0,
						minLength: 0,
						source: $.proxy(this, '_source')
					})
					.tooltip({
						classes: {
							'ui-tooltip': 'ui-state-highlight'
						}
					})

				this._on(this.input, {
					autocompleteselect: function (event, ui) {
						ui.item.option.selected = true
						this._trigger('select', event, {
							item: ui.item.option
						})
					},

					autocompletechange: '_removeIfInvalid'
				})
			},

			_createShowAllButton: function () {
				var input = this.input
				var wasOpen = false
				var wrapper = this.wrapper

				$('<a>')
					.attr('tabIndex', -1)
					// .attr("title", "Show All Items")
					.attr('spellcheck', 'false')
					.tooltip()
					.appendTo(this.wrapper)
					.button({
						icons: {
							primary: 'ui-icon-caret-1-s'
						},
						text: false
					})
					.removeClass('ui-corner-all')
					.addClass('custom-combobox-toggle ui-corner-right')
					.on('mousedown', function () {
						wasOpen = input.autocomplete('widget').is(':visible')
					})
					.on('click', function () {
						input.trigger('focus')

						// Close if already visible
						if (wasOpen) {

						}
					})
				wrapper.on('click', function () {
					input.autocomplete({
						open: function () {
							$('.ui-menu').width(input.outerWidth(true))
						}
					})
					input.autocomplete('search', '')
				})
			},

			_source: function (request, response) {
				var matcher = new RegExp(
					$.ui.autocomplete.escapeRegex(request.term),
					'i'
				)
				response(
					this.element.children('option').map(function () {
						var text = $(this).text()
						if (this.value && (!request.term || matcher.test(text))) {
							return {
								label: text,
								value: text,
								option: this
							}
						}
					})
				)
			},

			_removeIfInvalid: function (event, ui) {
				// Selected an item, nothing to do
				if (ui.item) {
					return
				}

				// Search for a match (case-insensitive)
				var value = this.input.val()
				var valueLowerCase = value.toLowerCase()
				var valid = false
				this.element.children('option').each(function () {
					if (
						$(this)
							.text()
							.toLowerCase() === valueLowerCase
					) {
						this.selected = valid = true
						return false
					}
				})

				// Found a match, nothing to do
				if (valid) {
					return
				}

				// Remove invalid value
				this.input
					.val('')
					// .attr("title", "No results fond. Please try another keyword.")
					.tooltip('open')
				this.element.val('')
				this._delay(function () {
					this.input.tooltip('close').attr('title', '')
				}, 2500)
				this.input.autocomplete('instance').term = ''
			},

			_destroy: function () {
				this.wrapper.remove()
				this.element.show()
			}
		})
	},
	/** *yyyy-mm-dd 00:00:00 ==> dd JAN yy****/
	en_mon_arr: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	],
	en_mon_arr1: {
		Jan: '01',
		Feb: '02',
		Mar: '03',
		Apr: '04',
		May: '05',
		Jun: '06',
		Jul: '07',
		Aug: '08',
		Sep: '09',
		Oct: '10',
		Nov: '11',
		Dec: '12'
	},
	formatDate (data) {
		var date =
			data.substring(8, 10) +
			' ' +
			this.en_mon_arr[Number(data.substring(5, 7)) - 1] +
			' ' +
			data.substring(0, 4)
		return date
	},
	/** ************** 转换为自己时区 ***************/
	changeToLocalTime (time) {
		if (time.indexOf('/') < 0) {
			if (time.split(' ')[3]) {
				time = `${time.split(' ')[2]}/${this.en_mon_arr1[time.split(' ')[1]]}/${time.split(' ')[0]} ${time.split(' ')[3]}:00`
			} else {
				time = `${time.split(' ')[2]}/${this.en_mon_arr1[time.split(' ')[1]]}/${time.split(' ')[0]} 00:00:00`
			}
		}
		var dateMilliseconds = new Date(time).getTime()
		var timezonemillsecond = -new Date().getTimezoneOffset() * 60000
		dateMilliseconds = dateMilliseconds + timezonemillsecond
		return new Date(dateMilliseconds)
	},
	/** * dd JAN yy ==> yyyy/mm/dd 00:00:00****/
	formatDetailDate (data) {
		var date =
			'20' +
			data.substring(7, 9) +
			'/' +
			this.en_mon_arr1[data.substring(3, 6)] +
			'/' +
			data.substring(0, 2) +
			' 00:00:00'
		return date
	},
	// 时间转换
	transitionData: function (time) {
		var MMM = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		]
		return (
			time.getDate() +
			' ' +
			MMM[time.getMonth()] +
			' ' +
			time
				.getFullYear()
				.toString()
				.substring(0, 4)
		)
	},
	//   /**
	// * 将数值四舍五入后格式化.
	// * @param num 数值(Number或者String)
	// * @param cent 要保留的小数位(Number)
	// * @param isThousand 是否需要千分位 0:不需要,1:需要(数值类型);
	// * @return 格式的字符串,如'1,234,567.45'
	// * @type String
	// */
	formatNumber: function (num, cent, isThousand) {
		var sign, cents
		if (num == '-' || num == undefined) {
			return '-'
		}
		num = num.toString().replace(/\$|\,/g, '')
		// 检查传入数值为数值类型
		if (isNaN(num)) {
			num = '0'
		}
		// 获取符号(正/负数)
		sign = num == (num = Math.abs(num))
		num = Math.floor(num * Math.pow(10, cent) + 0.50000000001) // 把指定的小数位先转换成整数.多余的小数位四舍五入
		cents = num % Math.pow(10, cent) // 求出小数位数值
		num = Math.floor(num / Math.pow(10, cent)).toString() // 求出整数位数值
		cents = cents.toString() // 把小数位转换成字符串,以便求小数位长度
		// 补足小数位到指定的位数
		while (cents.length < cent) {
			cents = '0' + cents
		}
		if (isThousand) {
			// 对整数部分进行千分位格式化.
			for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
				num =
					num.substring(0, num.length - (4 * i + 3)) +
					',' +
					num.substring(num.length - (4 * i + 3))
			}
		}
		if (cent > 0) {
			return (sign ? '' : '-') + num + '.' + cents
		} else {
			return (sign ? '' : '-') + num
		}
	},
	// 是input为空
	inputEmpty (id) {
		let oldVal
		$(id).find('input').off('click').on('click', function () {
			oldVal = $(this).val()
			$(this).val('')
		}).off('blur').on('blur', function () {
			if (!oldVal) {
				oldVal = $(this).val()
			}
			$(this).val(oldVal)
		})
	}
}
