import Vue from 'vue'
import App from './App/App.vue'
import router from './router'
import store from './store'

// 引入jquery
import $ from 'jquery'

// 引入bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// 引入bootstrap的icon
import './assets/bootstrap-icons/font/bootstrap-icons.css'

// 懒加载
import VueLazyload from 'vue-lazyload'

//  引入 anychart
import anychart from 'anychart'

// 引入公共Css 解决被覆盖
import Fonts from './fonts.css'
import './assets/css/main.css'

// 引入公共js
import global from './assets/js/global.js'
Vue.config.productionTip = false
Vue.use(VueLazyload)
Vue.prototype.$anychart = anychart
Vue.prototype.$$ = global

// 国际语言
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)
// const i18n = new VueI18n({
//     locale: localStorage.getItem('language') || 'en-US', // 设置默认使用语言
//     messages: {
//         'mys-CN': require('./common/app-mys.json'), // 中文语言包
//         'en-US': require('./common/app-en.json') // 英文语言包
//     }
// })

new Vue({
    router,
    store,
    $,
    Fonts,
    render: h => h(App)
}).$mount('#app')
