
export default {
    name: 'Footer',
    created () {
        this.$nextTick(function () {
            $.each(this.$router.options.routes, (i, n) => {
                if (i >= 1) {
                    this.Nav.push(n)
                }
            })
        })
    },
    mounted () {

    },
    props: [],
    data () {
        return {
            Nav: [],
            PDFsrc: '/sg/GeneralTermsAndConditions.pdf'
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
}
