
import Header from '@/views/Header/Header.vue'
import Footer from '@/views/Footer/Footer.vue'
export default {
	name: 'App',

	created () {
		this.$nextTick(function () {
			if (this.$route.path == '/sg/' || this.$route.path == '/sg') {
				this.$router.push('/sg/Home')
			}
		})
	},
	mounted () {
		this.$nextTick(function () {
			window.onload = () => {
				this.monitor()
			}
		})
	},
	provide () {
		// 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			relode: this.relode
		}
	},
	data () {
		return {

			routerF: true
		}
	},
	watch: {
		$route: {
			handler: 'routeCue',
			// 深度观察监听
			deep: true
		}
	},
	methods: {
		// 监听页面滚动条
		Scroll: function () {

			let scrollDistance = $(window).scrollTop();

			// Pricing
			if (scrollDistance >= 228) {
				$('#Pricing').find('.Option').addClass('levitate')
			} else {
				$('#Pricing').find('.Option').removeClass('levitate')
			}

			$(window).scroll(() => {
				scrollDistance = $(window).scrollTop();
				// Pricing
				if (scrollDistance >= 228) {
					$('#Pricing').find('.Option').addClass('levitate')
				} else {
					$('#Pricing').find('.Option').removeClass('levitate')
				}

			});
		},
		relode: function () {
			this.routerF = false
			this.$nextTick(() => {
				this.routerF = true
			})
		},
		routeCue: function (val, oldVal) {
			// if (val.name == 'Promotions') {
			// 	localStorage.clear()
			// }
			$(window).scrollTop(0);
			this.Scroll()
			if (val.meta.title) {
				document.title = val.meta.title
			}
			this.relode()
		},
		// 图片是否加载完成
		imgOnError: function () {

		},
		monitor: function () {
		}

	},
	components: {
		Header,
		Footer
	}
}
